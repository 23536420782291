/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
//import "./layout.css"
import "./style.css"
import Footer from "../components/footer.js"

const Layout = ({ children, page }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          subtitle
          description
          author
          copyright
        }
      }
      logo: contentfulAsset(title: {eq: "alycante-logo-ellisse-svg"}) {
      file {
      url
    }
  }
    }
  `)

  return (
    <>
      <Header site={data.site.siteMetadata} page={page} logo={data.logo}  />
        {children}
      <Footer site={data.site.siteMetadata} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
