import React from "react"
import {Link} from "gatsby"

import PropTypes from "prop-types"

const Header = ({ site, page, logo }) => (
    <header>
      <div className="container container-flex ">
        <div className="site-title">
          <img src={logo.file.url} alt="..." className="header-logo" />
          <p className="subtitle">{site.subtitle}</p>
        </div>
        <nav >
          <ul>
            <li>
              <Link 
                to="/"
                className={page === "home" ? "current-page" : ""}
              >
                Home
              </Link>
            </li>
            <li>
              <Link 
                to="/chi-siamo"
                className={page === "chi-siamo" ? "current-page" : ""}
              >
                Chi siamo
              </Link>
            </li>
            <li>
              <Link 
                to="/contacts"
                className={page === "contacts" ? "current-page" : ""}
              >
                Contatti
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );

Header.propTypes = {
  site: PropTypes.object,
  page: PropTypes.string,
}

Header.defaultProps = {
  site: {},
  page: ''
}

export default Header
