import React from "react";

const Footer = ({site}) => (
    <footer>
      <p>
        <strong>{site.subtitle}</strong>
      </p>
      <p>©{site.copyright} {new Date().getFullYear()},&nbsp;  
          <a href="/credits">Credits</a>.&nbsp;&nbsp; 
          Built with
          {` `}
          <a href="/jam-stack-architecture">JAM Stack Architecture</a>&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; <a style={{color: '#143774'}} href="/recent-posts">rc</a>
      </p>
    </footer>
  );

export default Footer;